import * as React from 'react'
import { mb, defaultMargins, defaultSmallMargins } from '../../utils/spacing';
import { styled } from "../../theme/theme"
import { graphql } from 'gatsby';

const StyledMargin = styled.div`
${props => mb(props['size'])};
`

export default (props) => {
  let size;
  switch (props.size) {
    case 'Small':
      size = defaultSmallMargins;
      break;
    case 'Default':
      size = defaultMargins;
      break;
    default:
      size = defaultMargins;
      break;
  }

  return (
    <StyledMargin style={{width: '100%', display: 'block'}} size={size}/>
  )
}

export const query = graphql`
    fragment Spacer on PRISMIC_PageBodySpacer{
        type
        primary{
            size
        }
    }
`
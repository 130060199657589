import BackgroundImage from "gatsby-background-image"
import * as React from "react"
import { ImageProps } from "./Img/Img"

interface BGImageProps {
  children?: React.ReactNode,
  image: ImageProps | null,
  className?: string,
  style?: React.CSSProperties,
}

export default function(props: BGImageProps) {
  const { image, children, className, style } = props
  
  if (image?.imageInfo?.childImageSharp) {
    return (
      <BackgroundImage
        className={className}
        style={style}
        Tag="section"
        fluid={image.imageInfo.childImageSharp.fluid}
      >
        {children}
      </BackgroundImage>
    )
  } else if (image?.imageInfo?.src) {
    return (
      <div
        className={className}
        style={{
          backgroundImage: `url(${image.imageInfo.src})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat", ...style,
        }}
      >
        {children}
      </div>
    )
  }

  return children
}
import * as React from "react"
import { styled } from "../theme/theme"

const StyledArrow = styled.div`
width: 100%;
border-top: 18px solid #C9CCCD;
position: relative;
filter: drop-shadow(0px 3px 0px rgba(17, 35, 43, 0.15));
background-color: ${props => props.light ? props.theme.secondaryColor : 'transparent'};


.arrow {
  width: 0; 
  height: 0; 
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 50px solid #C9CCCD;
  margin: 0 auto;
}

.grey-arrow{
  //filter: drop-shadow(0px 2px 4px rgba(17, 35, 43, 0.05));
}

.blue-arrow{
  position: absolute;
  top: 7px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  border-top-color: ${props => props.theme.secondaryColor};
}

`

const DownArrow = ({light}) => (
  <StyledArrow light={light}>
    {!light && 
    <div className={'arrow blue-arrow'}></div>
    }
    <div className={'arrow grey-arrow'}></div>
  </StyledArrow>
)

export default DownArrow
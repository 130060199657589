import * as React from "react"
import { ImageProps } from "../Img/Img"
import PreviewSafeBackgroundImage from "../PreviewSafeBackgroundImage"
import { styled } from "../../theme/theme"
import { py } from "../../utils/spacing"
import { Col, Container, Row } from "../Grid/Grid"
import DownArrow from "../DownArrow"

export interface BigTextHeroProps {
  children?: React.ReactNode,
  image?: null | ImageProps,
  videoUrl?: string,
  line1?: string,
  line2?: string,
  line3?: string,
  className?: string,
}

const StyledInner = styled(Col)`
  ${py({default: 3, sm: 6})};
`

const StyledHeading = styled.div`
    font-family: ${props => props.theme.headingFontFamily};
`

const StyledBackgroundImage = styled(PreviewSafeBackgroundImage)`
  position: relative;
  background-position: center;
  background-size: cover;
  text-align: center;
  
  font-style: normal;
  font-weight: bold;
  color: #fff;
  font-family: ${props => props.theme.headingFontFamily};

  .top-line{
    font-size: 30px;
    line-height: 90px; 
    
    @media(min-width: 500px){
      font-size: 60px;
    }
    
    @media(min-width: 900px){
      font-size: 70px;
    } 
  }
  
  .middle-line{
    position: relative;
    display: inline-block;
    font-size: 40px;
    line-height: 110%;
    text-transform: uppercase;
    font-family: 'Archivo Black', sans-serif;
    z-index: 1;
    
    &:before{
      content: " ";
      display: block;
      position: absolute;
      bottom: 10px;
      width: 100%;
      height: 20px;
      background-color: ${props => props.theme.primaryColor};
      z-index: -1;
    }
    
    @media(min-width: 500px){
      font-size: 60px;
    }
    
    @media(min-width: 900px){
      font-size: 125px;
    }
  }
  
  .bottom-line{
    font-size: 30px;
    line-height: 160%;
    @media(min-width: 500px){
      font-size: 40px;
    }
    
    @media(min-width: 900px){
      font-size: 56px;
    } 
  }
`



function BigTextHero(props: BigTextHeroProps) {
  const {
    image = null,
    line1 = '',
    line2 = '',
    line3 = '',
    className,
  } = props

  return (
    <React.Fragment>
      <StyledBackgroundImage className={className} image={image}>
          <Container>
            <Row>
              <StyledInner col={12}>
                <div className={'top-line'}>{line1}</div>
                <div className={'middle-line'}>{line2}</div>
                <div className={'bottom-line'}>{line3}</div>
              </StyledInner>
            </Row>
          </Container>
      </StyledBackgroundImage>
      <DownArrow light={true}/>
    </React.Fragment>
  )
}

BigTextHero.styled = {
  Heading: StyledHeading,
  Inner: StyledInner,
  BackgroundImage: StyledBackgroundImage,
}

export default BigTextHero;